import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"
import Card from "../../components/cards/card.js"
import ImageCard from "../../components/cards/imageCard.js"

import EMH from "../../images/hospital.jpg"
import CamelliaHealth from "../../images/camelliahealth.jpg"
import Northspring from "../../images/northspring.png"
import GriffenHouse from "../../images/griffinhouse.jpg"
import ElevationPT from "../../images/elevation-pt.jpg"

const HealthcarePage = props => (
  <CommunityLayout pageTitle="Healthcare">
    <div>
      <ImageCard src={EMH} alt="Evans Memorial Hospital">
        <h2>Evans Memorial Hospital</h2>
        <p>
          200 North River Street
          <br />
          Claxton, Ga 30417
          <br />
          (912) 739-5000
          <br />
          <a href="https://www.evansmemorialhospital.org/" target="_blank">
            www.evansmemorialhospital.org
          </a>
        </p>
        <p>
          Evans Memorial Hospital is a licensed, 49 bed acute care hospital
          offering an exceptional range of medical services.{" "}
        </p>
      </ImageCard>

      <ImageCard src={CamelliaHealth} alt="">
        <h2>Camellia Health and Rehabilitation Center</h2>
        <p>
          700 East Long Street
          <br />
          Claxton, GA 30417
          <br />
          (912) 739-2245
          <br />
          <a href="http://www.camelliahealth.org/index.html" target="_blank">
            www.camelliahealth.org
          </a>
        </p>
        <p>
          Camellia Health and Rehabilitation Center is a fully accredited
          facility with 83 beds and 2 attending physicians.
        </p>
      </ImageCard>

      <ImageCard src={Northspring} alt="">
        <h2>NorthSpring Senior Living</h2>
        <p>
          4 N Spring Street
          <br /> 
          Claxton, GA 30417
          <br />
          (912) 739-4000
          <br />
          <a
        href="https://www.facebook.com/northspringseniorliving"
        target="_blank"
        >Northspring Senior Living Facebook Page</a>
        </p>
        <p>
          NorthSpring Senior Living is a licensed 19 bed assisted living
          facility. Healthy living and active lifestyles are the main emphasis.{" "}
        </p>
      </ImageCard>

      <ImageCard src={GriffenHouse} alt="">
        <h2>The Griffin House and Alzheimer’s Residence</h2>
        <p>
          107 W Liberty St
          <br /> 
          Claxton, GA 30417
          <br />
          (912) 739-3226
          <br />
          <a
        href="https://www.facebook.com/griffinhouseseniorliving/"
        target="_blank"
        >Griffin House Facebook Page</a>
        </p>
        <p>
          The Griffin House and Alzheimer’s Residence is a 10- bed assisted
          living facility with an additional 14 bed alzheimer’s unit.
        </p>
        </ImageCard>

      <Card>
        <h2>Evans County Health Department</h2>
        <a
          href="https://www.sehdph.org/our-counties/evans-county-health-department/"
          target="_blank"
        >
          www.sehdph.org
        </a>
      </Card>
      <Card>
        <h2>Norburg Chiropractic & Wellness Center </h2>
        <a href="https://www.facebook.com/norburgchiro/" target="_blank">
          www.norburgchiro.com
        </a>
      </Card>
      <Card>
        <h2>Claxton Family Dentistry </h2>
        <a href="http://www.claxtonfamilydentistry.com/" target="_blank">
          www.claxtonfamilydentistry.com
        </a>
      </Card>
      <Card>
        <h2>Claxton Smiles</h2>
        <a href="https://www.claxtonsmiles.com/" target="_blank">
          www.claxtonsmiles.com
        </a>
      </Card>
      <Card>
        <h2>Jack Strickland Rehabilitation Center</h2>
        <a
          href="https://www.evansmemorialhospital.org/rehabilitation.html"
          target="_blank"
        >
          www.evansmemorialhospital.org
        </a>
      </Card>
      <Card>
        <h2>
          Surgical Associates Division of Evans Memorial Hospital, Dr. Kyle
          Parks
        </h2>
        <a
          href="https://www.evansmemorialhospital.org/surgical-services.html"
          target="_blank"
        >
          www.evansmemoriahospital.org
        </a>
      </Card>
      <Card>
        <h2>Fresenius Kidney Care</h2>
        <a href="https://www.freseniuskidneycare.com/" target="_blank">
          www.freseniuskidneycare.com
        </a>
      </Card>
    </div>
    <br /><br />
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/23CommunityResourceGuide.pdf">Download the Full Community Resource Guide</a>
      <br /><br />
  </CommunityLayout>
)

export default HealthcarePage
